// Fonts
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,700;1,400&display=swap');

// Colors
$violet:        #41449f;
$green:         #00746b;
$red:           #ff4141;
$orange:        #f28d4f;
$blue:          #72b5e4;

// Brand colors
$primary:         $violet;
$primary-o:       rgba($primary, 0.1);
$success:         $green;
$info:            $blue;
$warning:         $orange;
$danger:          $red;


// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base: 'Raleway', sans-serif;

//** Global textual link color.
$link-color:            $primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: none;

$btn-disabled-opacity:        .4;

$facet-block-text-size:        16px;
$facet-block-toggle-size:      14px;

$sizes: (
  15: 15%,
  30: 20%
);

@import '~bootstrap/scss/bootstrap.scss';

@import "scss/utilities";
@import "scss/footer";
@import "scss/catalog";
@import "scss/product";
@import "scss/facets";
@import "scss/flyout";
@import "scss/testimonial";
@import "scss/overrides";
