.commerce-product--catalog {
  margin: 15px auto;
  max-width: 370px;
  border-bottom: 2px solid $primary;

  &:hover {
    .commerce-product--catalog__hover-text {
      visibility: visible;
      opacity: 1;
    }

    .commerce-product--catalog__info {
      padding: 0 15px;
      background-color: $primary;
      color: white;

      .field--name-title {
        padding-right: 10px;
      }

      a {
        color: white;
      }
    }
  }

  &__image {
    position: relative;
  }

  &__info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 80px;
    border-top: 2px solid white;
    font-size: $h6-font-size;
    transition: all .4s ease;

    .field--name-title {
      padding-right: 40px; // to avoid title collapsing
      font-size: $h5-font-size;
      font-weight: bold;
      line-height: 1.2;
      transition: padding-right .4s ease;

      a {
        color: $gray-800;
        font-weight: bold;

        &:before {
          position: absolute;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          content: "";
        }
      }
    }
  }

  &__hover-text {
    position: absolute;
    top: 0;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, .8);
    color: white;
    text-align: center;
    font-size: $h6-font-size;
    opacity: 0;
    transition: all .4s ease;

    &:hover {
      color: white;
    }
  }
}
