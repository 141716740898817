.commerce-product--full {
  &__contents {
    padding: 0 25px;
  }

  // Field labels
  .field--label,
  .panel-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  // Title and price
  .field--name-price,
  .field--name-title {
    font-size: $h1-font-size;
  }

  // Color
  .field--name-variations {
    h3 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      margin: 0 25px 5px 0;
    }

    .field--item {
      display: flex;
      align-items: center;
    }

    ul {
      padding: 0;
      list-style: none;
      margin: 0;

      li {
        display: inline-block;
        margin-right: 5px;
      }
    }

    .field__items {
      margin-top: 25px;
    }
  }

  // Price
  .field--name-price {
    font-weight: bold;
  }

  .attribute-widgets {

    // Override panel styles to match designs
    .product--rendered-attribute,
    .panel-title,
    .panel-body,
    .panel-heading {
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent;
      box-shadow: none;
    }

    .panel-title {
      margin-right: 25px;
      display: flex;
      align-items: center;
    }

    .product--rendered-attribute {
      display: flex;
      margin: 30px 0;

      .form-item {
        margin: 0;
      }

      .option {
        margin: 0;
        padding: 0;
        border-radius: 0;
      }

      .control-label {
        border: 2px solid transparent;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    .product--rendered-attribute {

      // selected and active variations
      .form-radio:checked~label.option {
        border: 2px solid $primary;
      }
    }
  }

  // Select
  .form-type-select {
    label {
      margin-right: 25px;
      text-transform: uppercase;
    }
  }

  .select-wrapper {
    width: auto;
  }

  .form-control {
    border-color: $primary;
  }

  // Submit button
  .form-submit {
    max-width: 370px;
    width: 100%;
  }

  // Vertical spacing
  .field--name-body,
  .field--name-variations,
  .field--name-sku {
    margin: 30px 0;
  }
}

// Product categories
.field--name-field-product-categories,
.field--name-field-special-categories {
  font-size: 14px;

  div+div:before {
    content: "|";
    margin: 0 10px;
  }

  &:empty {
    display: none !important;
  }
}
