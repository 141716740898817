.cart-offcanvas {
  position: fixed;
  min-height: 100%;
  max-height: none;
  top: 0;
  display: block;
  background: #fff;
  transition: transform .3s cubic-bezier(.4,0,.6,1);
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  overflow: hidden;
  z-index: $zindex-modal;
  &--left {
    height: 100%;
    width: 30em;
    max-width: 100%;
    transform: translate3d(-17em, 0, 0);
  }
  &--right {
    height: 100%;
    width: 30em;
    max-width: 100%;
    right: 0;
    transform: translate3d(17em,0,0);
  }
  &.is-open {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  &.is-closed {
    max-height: 100%;
    visibility: hidden;
    box-shadow: none;
  }
}


.cart-offcanvas-bg {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: $zindex-modal-backdrop;
  left: -100%;
  background-color: transparent;
  transition: background-color .4s cubic-bezier(.23,1,.32,1) 0s;
  &.is-animating,
  &.is-open {
    left: 0;
    background-color: rgba(0,0,0,.68);
    visibility: visible;
  }
  &.is-closed {
    visibility: hidden;
  }
}


.cart--cart-offcanvas {
  height: 100%;
  overflow: auto;
}
